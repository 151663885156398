import React, { useState , useEffect} from 'react';
import { motion } from 'framer-motion';
// import boat from '../assets/boat.mov'

const Carousel = ({ images, captions, interval = 10000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVideo, setIsVideo] = useState(false);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));

  };

  useEffect(() => {

    const timer = setTimeout(handleNextClick, interval)
    if(currentIndex === 0){
      console.log(currentIndex, 'true')
      setIsVideo(true);
    }else{
      setIsVideo(false);
    }

    return() => {
      clearTimeout(timer);
    }
  }, [currentIndex])

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px', overflow: 'hidden' }}>
      <motion.div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
      {/* {isVideo ? 
      (<video autoPlay loop muted className="w-screen">
        <source src={boat} type="video/mp4" />
      </video>) :
      ( */}
        <motion.img
          key={currentIndex}
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1}}
        />
      {/* )
} */}



      <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%', 
            height: '100%',
            background: 'linear-gradient(to top, rgb(13, 13, 13), transparent)', 
          }}
      />
      <center>
      {captions[currentIndex] && ( 
        <motion.div
          className='textCarousel'
          style={{
            position: 'absolute',
            bottom: '10%',
            transform: 'translateX(-50%)',
            color: 'white',
            height:'110px',
            width:'300px',
            fontSize: '30px',
            padding: '8px',
          }}
        >
          {captions[currentIndex]}
        </motion.div>
        )}
        </center>

      </motion.div>


      
        <button onClick={handlePrevClick} style={{ 
          position: 'absolute', 
          top: '50%', 
          left: '20px', 
          transform: 'translateY(-50%)', 
          zIndex: 1, 
          background: 'none', 
          border: 'none', 
          padding: 0, 
          cursor: 'pointer' 
        }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ 
            display: 'block', 
            color: '#FFFFFF' 
          }}>
            <polyline points="15 18 9 12 15 6" />
          </svg>
        </button>

      <button onClick={handleNextClick} style={{ 
        position: 'absolute', 
        top: '50%', 
        right: '20px', 
        transform: 'translateY(-50%)', 
        zIndex: 1, 
        background: 'none', 
        border: 'none', 
        padding: 0, 
        cursor: 'pointer' 
      }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ 
          display: 'block', 
          color: '#FFFFFF' 
        }}>
          <polyline points="9 18 15 12 9 6" />
        </svg>
      </button>
    </div>
  );
};

export default Carousel;
