import React from 'react';
import ChemicalList from './ChemicalList';
import { useParams } from 'react-router-dom';
import cleaningImage from '../assets/cleaning.jpeg';
import miningImage from '../assets/mining.jpeg';
import pillsImage from '../assets/pills.jpeg';
import waterImage from '../assets/water.jpeg';
import foodImage from '../assets/food.jpeg';
import industrialCleaningImage from '../assets/industrialcleaning.jpeg';
import '../styles/IndustriesDetails.css'; 

const Industries = () => {
    
  // Define industry items and corresponding image assets
  const industryItems = [
    'Water Treatment',
    'Mining',
    'Industrial Cleaning',
    'Food',
    'Pharmaceutical',
    'Household Cleaning'
  ];

  // Get the industryId from URL using useParams hook
  const { industryId } = useParams();

  // Dictionary mapping industry names to image assets
  const imageDictionary = {
    'Water Treatment': waterImage,
    'Mining': miningImage,
    'Household Cleaning': cleaningImage,
    'Pharmaceutical': pillsImage,
    'Industrial Cleaning': industrialCleaningImage,
    'Food': foodImage
  };

  const industryInfo = {
    'Water Treatment': 'Discover our specialized range of water treatment chemicals and technologies, designed to optimize water quality and ensure effective treatment processes.',
    'Mining': 'Comprehensive selection of chemical products and services for the mining industry, supported by strong global suppliers.',
    'Household Cleaning': 'Discover efficient industrial cleaning solutions with short lead time for your business needs.',
    'Pharmaceutical': 'Supporting the pharmaceutical industry with a wide selection of  reagents, catalysts, and raw chemicals.',
    'Industrial Cleaning': 'Discover efficient industrial cleaning solutions with short lead time for your business needs.',
    'Food': 'Discover our food products for all of your industrial processing needs.'
  }

  const detailHeader = {
        'Water Treatment': 'Industrial and Municipal Water Treatment',
        'Mining': 'Elevate Your Mining Operations',
        'Household Cleaning': 'Quality Cleaning Chemicals',
        'Pharmaceutical': 'Innovating Pharmaceutical Solutions',
        'Industrial Cleaning': 'Chemical Solutions for Effective Industrial Cleaning',
        'Food': 'Pioneering Excellence in Food Chemical Solutions'
    
  }

  const moreDetails = {
    'Water Treatment': 'Benefit from our reliable solutions and technical expertise tailored to meet your industrial and municipal water treatment needs. At our chemical import company, we offer a comprehensive suite of water treatment solutions, including a diverse selection of chemicals and specialized technologies. Our focus is on delivering safe, high-quality products that optimize water quality and sustainability. Leveraging our global networks and extensive application knowledge, we ensure reliable access to essential chemicals through a streamlined supply chain. Partner with us to benefit from our expertise in water treatment ingredients and specialty solutions, backed by dedicated customer support.',
    'Mining': 'Discover how Samirian Chemical tailored solutions and technical expertise can optimize your mining operations. We specialize in providing high-quality mining chemicals sourced from trusted suppliers worldwide. Our focus is on enhancing efficiency and productivity in mineral extraction using sustainable technologies. Benefit from our global networks and industry knowledge, ensuring seamless access to essential mining chemicals. Our dedicated customer support team is committed to providing personalized service and innovative solutions to support your mining endeavors. Experience the Samirian Chemicals advantage and transform your mining operations with our industry-leading solutions and services.',
    'Household Cleaning': 'Discover our comprehensive range of chemical ingredients tailored for household cleaning applications. At Samirian Chemicals, we specialize in providing high-quality chemical solutions essential for effective household cleaning. From versatile ingredients like Sodium Hydroxide and Potassium Hydroxide to specialized compounds such as Sodium Metabisulfite, our products are designed to enhance cleaning performance in various household applications. Trust in our expertise to deliver quality chemical solutions that meet the specific needs of household cleaning tasks, ensuring efficient and reliable results.',
    'Pharmaceutical': 'Samirian is a premier provider of high-quality chemicals and compounds for the pharmaceutical industry. Renowned for our unwavering commitment to excellence, innovation, and compliance, we deliver advanced solutions that meet the stringent demands of pharmaceutical manufacturers and researchers globally. Samirian offers personalized services, including technical support, product customization, and supply chain solutions. Our global distribution network ensures timely delivery and consistent supply, enabling our clients to seamlessly maintain their production schedules. By partnering with us, pharmaceutical manufacturers and researchers can access top-tier chemical solutions that support the creation of groundbreaking medications, ultimately contributing to better healthcare outcomes for people around the world.',
    'Industrial Cleaning': 'Explore our range of chemical solutions tailored for industrial cleaning applications. At Samirian Chemicals, we provide a selection of essential chemicals, including Sodium Hydroxide, Potassium Hydroxide, Sodium Metabisulfite, and more, designed to address various industrial cleaning needs. Our expertise extends to assisting customers in optimizing cleaning processes and formulations for efficient cleaning outcomes. Trust us to deliver chemical solutions tailored to meet your industrial cleaning requirements.',
    'Food': 'Samirian is a leading provider of high-quality chemicals specifically designed for the food industry. With a commitment to safety, innovation, and sustainability, Samirian has established itself as a trusted partner for food manufacturers, processors, and culinary professionals around the world. We supply a number of chemicals for food flavoring, preservation, brining, and other industrial processes. By partnering with us, food manufacturers and professionals can ensure they are using the best possible ingredients to create safe, delicious, and nutritious products for consumers worldwide.'
  }

  const chemicalListPerIndustry = {
    'Water Treatment': [0,1,2,3,4,5,6],
    'Mining': [5,8,9,12,16,17,18],
    'Household Cleaning': [3,8,10,11,14,18],
    'Pharmaceutical': [1,3,8,10,12,17,19],
    'Industrial Cleaning': [8,11,12,14,17,18],
    'Food': [0,1,10,11,12,16,17]
  }

  // Check if industryId is valid
  if (!industryItems.includes(industryId)) {
    return <div className="industry-details">Industry not found!</div>;
  }

  return (
    <div className="industry-container">
      <title>{industryId}</title>
        <div className="industry-details">
            <div className="image-container">
                <img className="industry-image" src={imageDictionary[industryId]} alt={industryId} />
                <h2 className="industry-title">{industryId}</h2>
                <p className= "industry-title-header">{industryInfo[industryId]}</p>
            </div>
            <div className='industry-detail-body'>
                <h1>
                    {detailHeader[industryId]}
                </h1>
                <p className="industry-description">
                    {moreDetails[industryId]}
                </p>
            </div>
            <div>
                <h1>
                Chemical List
                </h1>
                <ul className='chemical-list'>
                    <ChemicalList indexListArr = {chemicalListPerIndustry[industryId]}/>
                </ul>
            </div>

        </div>
    </div>

  );
};

export default Industries;
