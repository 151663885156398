import React from 'react';

const ChemicalList = ({indexListArr}) => {

    const entireList = [
    "Ammonium Bicarbonate",
    "Ammonium Chloride",
    "Ammonium Persulfate",
    "Ammonium Sulfate",  
    "Bronopol", 
    "Copper Sulfate",
    "Lead Nitrate ",
    "Methyl Ethyl Ketone",
    "Oxalic Acid",
    "Potassium Amylxanthate",
    "Potassium Carbonate",
    "Potassium Hydroxide",
    "Sodium Metabisulfite",
    "Sodium Nitrate",
    "Sodium Thiosulfate",
    "Sodium Hydroxide",
    "Sodium Persulfate",
    "Sodium Sulfide",
    "Sulfamic Acid",
    "Urea"
    ];

    const chemicalListItem = indexListArr.map((currentIndex) => (
        <li> {entireList[currentIndex]}</li>
    ))


    return(
        <>
            {chemicalListItem}
        </>
    )

}

export default ChemicalList;
