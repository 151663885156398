import React from 'react';
import { Link } from 'react-router-dom';
import careersImage from '../assets/careers.jpg';
import '../styles/Careers.css';

const Careers = () => {


    return (
        <div className='careers-container'>
            <title>Careers</title>
            <div className="image-container">
                <img className="careers-image" src={careersImage} alt={'careers image'} />
                <h2 className="careers-title">Careers</h2>
            </div>
            <div className='careers-detail-body'>
                <h1 className='careers-header'> Working at Samirian</h1>
                <p className='careers-description'>
                    Samirian Chemicals has 40 years of experience in the chemicals field, but is always looking to grow.
                    We are a creative, solution-oriented and fast-paced environment aiming to increase our share of the
                    chemical supply chain management market. Samirian's focus on creativity in our solutions allows for
                    new room for growth in a stagnating chemicals industry.
                    <br></br>
                    In addition, all work at Samirian is fully remote with flexible hours, allowing room for a healthy
                    work-life balance with benefits for full-time employees. 
                </p>
            </div>
            <br></br>
            <div className='careers-detail-body'>
                <h1 className='careers-header'> Who We're Looking For</h1>
                <p className='careers-description'>
                    Samirian is looking for employees, interns, and consultants willing to learn in a fast-paced environment
                    and apply creative solutions to every problem they face. Our sales, marketing, and IT divisions are
                    growing and always searching for new talent. Whether you're an experienced industry veteran or a college 
                    student looking to intern, there is a place for you at Samirian for you.
                </p>
            </div>
            <br></br>
            <div className='careers-learnmore'>
                <h1 className='careers-header'>Interested?</h1>
                <p>Reach out to us at <a href='mailto:hr@samirian.com'>hr@samirian.com</a></p>
            </div>
        </div>
    )
};

export default Careers;