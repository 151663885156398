import React from 'react';
import { Link } from 'react-router-dom';
import officeImage from '../assets/office.jpg';
import biglogo from '../assets/samirianlogo.png'
import textlogo from '../assets/textlogo.png'
import '../styles/AboutUs.css';

const AboutUs = () => {


    return (
        <div className='aboutus-container'>
            <title>About</title>
            <div className="image-container">
                <img className="aboutus-image" src={officeImage} alt={'industryId'} />
                <h2 className="aboutus-title">About Us</h2>
            </div>
            <div className='aboutus-detail-body'>
                <h1 className='aboutus-header'> Our Journey</h1>
                <p className='aboutus-description'>
                    Founded in 1984, Samirian Chemicals began as the American arm of Beaming, with the primary
                    function of supplying sulfamic acid from Taiwan to the United States. Over the years, we
                    have expanded our reach and capabilities, developing into the supply chain management company we are today. 
                    Samirian's Asia office works with manufacturers in Taiwan and China, and our US office works with 
                    a robust network of warehouses across the United States to supply our customers with chemicals on demand.
                </p>
            </div>
            <div className='aboutus-detail-body'>
                <h1 className='aboutus-header'> Our Mission</h1>
                <p className='aboutus-description'>
                    At Samirian Chemicals, we are dedicated to excellence. From manufacturing to packaging and delivery,
                    we ensure quality, reliability, and above all the highest degree of customer service. Our US offices control sales,
                    logistics, and customer service, while our China and Taiwan offices coordinate activities among the
                    different manufacturing facilities to ensure high quality chemicals can arrive at our customers' locations in a timely manner.
                </p>
            </div>
            <br></br>
            <div className='aboutus-detail-body'>
                <h1 className='aboutus-header'> Our Commitment to You</h1>
                <p className='aboutus-description'>
                    We understand that the backbone of our success is our relationship with you, our partners and customers.
                    By listening and understanding your needs, we have grown into the quality company we are today. Our dedicated
                    team works closely with you to offer products, packaging, and delivery tailored to your specific requirements. Samirian employs creative, individualized solutions to all of our customers to ensure a seamless supply chain from start to finish.
                    <br></br>
                    <br></br>
                    Join us in our continuous pursuit of excellence and creativity. Together, we can achieve remarkable solutions to simplify your daily operations.
                </p>
            </div>
            <div className='aboutus-learnmore'>
                <Link to="/contact">
                    <button className="cta-button">Contact Us</button>
                </Link>
            </div>
        </div>
    )
};

export default AboutUs;