import React, { useRef } from 'react';
import contactus from '../assets/contactus.jpeg';
import '../styles/Contact.css';
import emailjs from '@emailjs/browser'

const ContactPage = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const emailObj = {};
    emailObj['firstName'] = document.getElementById("firstName").value;
    emailObj['lastName'] = document.getElementById("lastName").value;
    emailObj['phone'] = document.getElementById("phone").value;
    emailObj['email'] = document.getElementById("email").value;
    emailObj['message'] = document.getElementById("message").value;

    console.log(emailObj);
    emailjs.send('service_x53vpid', 'template_lt95bnk', emailObj, {
      publicKey: "JZzWQDeX_c1YzqaJY"
    })
      .then((e) => {
        console.log(e);
      }).catch((err) => {
        console.log(err);
      })


  }


  return (
    <div className='contact-container'>
      <title>Contact</title>
      <div className='contact-header'>
        <img className='contactus-image' src={contactus} alt='contact us' />
      </div>
      <div className='contact-body'>
        <div className="contact-info">
          <h1>Contact Us</h1>
          <div className="">
            <p>1999 S. Bascom Ave. Suite 700</p>
            <p>Campbell, CA 95008</p>
          </div>
          <div className="">
            <p>+1 (408) 558 8282</p>
            <p>customerservice@samirian.com</p>
          </div>
        </div>
        <div className='contact-field'>
          <div className='contact-form'> 
            <form ref={form} onSubmit={sendEmail}>
              <div >
                <div className="contact-label">
                  <label> First Name</label>
                </div>
                <div>
                  <input id="firstName" className="contact-input" type="text" />
                </div>
              </div>
              <div >
                <div className="contact-label">
                  <label> Last Name</label>
                </div>
                <div>
                  <input id="lastName" className="contact-input" type="text" />
                </div>
              </div>
              <div >
                <div className="contact-label">
                  <label> Phone Number</label>
                </div>
                <div>
                  <input id="phone" className="contact-input" type="text" />
                </div>
              </div>
              <div>
                <div>
                  <label className="contact-label">Email</label>
                </div>
                <div>
                  <input id="email" className="contact-input" type="email" />
                </div>
              </div>
              <div>
                <div>
                  <label className="contact-label">Message</label>
                </div>
                <div>
                  <textarea id="message" className="contact-message" rows="8"></textarea>
                </div>
              </div>
              <center>
                <div className='form-button'>
                <button type="submit">Submit</button>
                </div>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
