import React from 'react';
import "../styles/Footer.css";
import Logo from '../assets/whitelogo.png';
import linkedin from '../assets/linkedin.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section footer-sitemap">
        <h3>Sitemap</h3>
        <a href="/home">Home</a>
        <a href="/aboutus">About Us</a>
        <a href="/industries">Industries</a>
        <a href="/contact">Contact</a>
      </div>
      <div className="footer-section footer-address">
        <img src={Logo}></img>
        <p>1999 S. BASCOM AVE.</p>
        <p>Suite 700</p>
        <p>Campbell, CA 95008</p>
      </div>
      <div className="footer-section footer-contact">
        <h3>Contact Us</h3>
        <p>+1 (408) 558 8282</p>
        <p><a href="mailto:customerservice@samirian.com">customerservice@samirian.com</a></p>
        <a
          href="https://www.linkedin.com/company/samirian-chemicals-inc-/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          <img
            src={linkedin}
            alt="LinkedIn"
            className="footer-icon"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;