import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation
import '../styles/Header.css';
import Logo from '../assets/samirianlogo.png';
import SmallLogo from '../assets/smallwhitelogo.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar-content">
      <nav className="navbar">
        <div className="navbar-logo"><a href='/'><img src={Logo} alt={"Samirian logo"}></img></a></div>
        <div className={`navbar-links ${menuOpen ? 'active' : ''}`}>
          <a href="/">Home</a>
          <a href="/aboutus">About</a>
          <a href="/industries">Industries</a>
          <a href="/careers">Careers</a>
          <a href="/contact">Contact</a>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </div>
  );
};

export default Header;
