import React from 'react';
import { Link } from 'react-router-dom';
import cleaning from '../assets/cleaning.jpeg';
import mining from '../assets/mining.jpeg';
import pills from '../assets/pills.jpeg';
import water from '../assets/water.jpeg';
import food from '../assets/food.jpeg';
import industrialcleaning from '../assets/industrialcleaning.jpeg';
import '../styles/IndustryFocus.css'

const IndustryFocus = ({ industryItems }) => {
  const imageDictionary = {
    'Water Treatment': water,
    'Mining': mining,
    'Household Cleaning': cleaning,
    'Pharmaceutical': pills,
    'Industrial Cleaning': industrialcleaning,
    'Food': food,
  };

  return (
    <div className=".industry-focus-container">
      <title>Industries</title>
      <div className="grid-container">
        {industryItems.map((currentItem) => (
          <Link to={`/industries/${currentItem}`} key={currentItem}>
            <div
              className="industry-item"
              style={{
                backgroundImage: `url(${imageDictionary[currentItem]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className='industry-text'>
                {currentItem}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default IndustryFocus;
