import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from './Carousel';
import trucking from '../assets/trucking.jpg';
import service from '../assets/service.jpeg';
import truck from '../assets/truck.jpg';
import IndustryFocus from './IndustryFocus';
import '../styles/HomePage.css';

const HomePage = () => {
  const images = [truck, service,trucking];
  const captions = ['Excellence Through Quality', 'Personalized Supply Chain Management', 'Solutions for your Chemical Supply Chain Needs'];
  const industryItems = ['Water Treatment', 'Mining', 'Industrial Cleaning', 'Food', 'Pharmaceutical', 'Household Cleaning'];

  return (
    <div className="home-container">
      <title>Home</title>
      <div className='carousel-container'>
        <Carousel images={images} captions={captions} />
      </div>

      <div className="home-content">
        <div className="description-container">
          <h1 className="home-title">Creative Supply Chain Solutions</h1>
          <p className="home-description">
            Here at Samirian, we strive to offer customers specialized chemical supply chain services. We have offices in North America and overseas with our suppliers to coordinate shipments of chemicals all around the world. Samirian strives to make creative solutions for our customers' supply chain needs, from personalized specifications and packaging to different inventory locations and shipping timelines.  
          </p>
        </div>
        <div className='calltoaction'>
        <h1>Need additional information? Need a quote?</h1>
        <Link to="/contact">
          <button className="cta-button">Contact Us</button>
        </Link>
        </div>
        <div className="industry-focus-container">
          <h2 className="industry-focus-title">Most Common Industries</h2>
          <IndustryFocus industryItems={industryItems} />
        </div>
        <div className ="industry-focus-container">
          <div>
              <h1>About Samirian:</h1>
              <p> We are a global distributor of specialty chemicals serving many industries. By creating and maintaining strong relationships with our partnering companies, we source the highest quality products.  Samirian strives to provide your business with quality chemicals and personalized supply chain solutions to simplify your daily operations. </p>
              <Link to="/aboutus">
                  <button className="cta-button">Learn More</button>
              </Link>
          </div>
        </div>  
      </div>
    </div>
  );
};

export default HomePage;
