import React from 'react';
import ChemicalList from './ChemicalList';
import IndustryFocus from './IndustryFocus';
import industries from '../assets/chemicalplant.jpg';

const IndustriesHome = () => {

  const industryItems = ['Water Treatment', 'Mining', 'Industrial Cleaning', 'Food', 'Pharmaceutical', 'Household Cleaning'];

  return (
    <div className='home-content' >
      <div className='industry-focus-container'>
        <h1>Industries</h1>
        <p>Discover some of the many chemical industries we serve through our supply chain services!
          We have helped our customers in a variety of different industries get chemicals according to their
          timelines and to their customizable specifications. We have past experience in these industries and
          are always looking to expand into more. If you have any inquiries, please feel free to reach out.
        </p>
      </div>
      <IndustryFocus industryItems={industryItems}/>

      <h1>
        Chemical List
      </h1>
      <ul className='chemical-list'>
        <ChemicalList indexListArr = {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]}/>
      </ul>

    </div>
  );
};

export default IndustriesHome;