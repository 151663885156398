import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Industries from './components/Industries';
import IndustryDetails from './components/IndustryDetails';
import Careers from './components/Careers';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import { logPageView } from './components/analytics';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);
};

const App = () => {
  usePageTracking();
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/industries/:industryId" element={<IndustryDetails />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;

